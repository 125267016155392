import $ from 'jquery';

import './scss/style.scss';

let activeArtist = 0;
let data = [];
let loaded = 0;

const $data = $('#data input[type=hidden]');
const hasData = $data.length;
const $loading = $('#loading');
const hasLoading = $loading && $loading.length;
const $loadingValue = $loading.find('.value');
const hasLoadingValue = $loadingValue && $loadingValue.length;
const $loadingPercentage = $loading.find('.percentage');
const hasLoadingPercentage = $loadingPercentage && $loadingPercentage.length;
const $start = $('#start');
const hasStart = $start && $start.length;
const $controlMenu = $('#control-menu');
const hasControlMenu = $controlMenu && $controlMenu.length;
const $videoGallery = $('#video-gallery');
const hasVideoGallery = $videoGallery && $videoGallery.length;
const $artistsList = $('#artists-list');
const hasArtistsList = $artistsList && $artistsList.length;

const getData = async () => {
  if (hasData) {
    $data.each((index) => {
      const $current = $data.eq(index);

      if (
        $current &&
        $current.attr('nome') &&
        $current.attr('nome').length &&
        $current.attr('video') &&
        $current.attr('video').length
      ) {
        data.push({
          name: $current.attr('nome'),
          file: $current.attr('video'),
        });
      }
    });
  }

  return data;
};

const stopAllVideos = () => {
  const $videos = $videoGallery.find('.video-item .player');

  if ($videos && $videos.length) {
    $videos.each((index) => {
      const $current = $videos.eq(index);
      
      if ($current && $current.length) {
        $current.get(0).pause();
        $current.get(0).currentTime = 0;
      }
    });
  }
};

const updateGallery = (position) => {
  const $items = $videoGallery.find('.video-item');
  const $current = $items.eq(position).find('video');

  activeArtist = position;
  stopAllVideos();

  if ($current && $current.length) {
    $current.get(0).play();
  }

  $items.removeClass('active previous first last');
  $items.addClass('upcoming');

  if (position >= data.length - 1) {
    $items.slice(0, position - 1).removeClass('previous').addClass('upcoming');
  } else {
    $items.slice(0, position).removeClass('upcoming').addClass('previous');
  }

  if (position === 0 && data.length > 2) {
    $items.eq(data.length - 1).removeClass('upcoming').addClass('previous');
  }

  $items.eq(position).removeClass('previous upcoming').addClass('active');
}

const onVideoEnded = (index) => {
  if (index === activeArtist) {
    const current = activeArtist + 1 >= data.length ? 0 : activeArtist + 1;
    updateGallery(current);
  }
};

const onClickArtist = (index) => {
  updateGallery(index);
  $controlMenu.click();
};

const onClickStart = () => {
  updateGallery(activeArtist);
  $start.addClass('hide');
};

const onDocumentReady = async () => {
  const data = await getData();

  if (data && data.length && hasVideoGallery && hasArtistsList) {  
    if (hasControlMenu) {
      const onClickControlMenu = () => {
        if ($controlMenu.hasClass('show')) {
          $controlMenu.removeClass('show').addClass('hide');
          $artistsList.addClass('show');
        } else {
          $controlMenu.removeClass('hide').addClass('show');
          $artistsList.removeClass('show');
        }
      };
  
      $controlMenu.click(onClickControlMenu);
    }

    $artistsList.append('<ul />');

    data.forEach((artist, index) => {
      if (
        artist &&
        artist.name &&
        artist.name.length &&
        artist.file.length &&
        artist.file.length
      ) {
        const $videoItemDOM = $('<div class="video-item" />');
        const $nameDOM = $(`<h3 class="artist-name"><span>${artist.name}</span></h3>`);
        const $selectArtistItemDOM = $('<li></li>');
        const $details = $('<div class="details"><div class="color"></div><div class="color"></div><div class="color"></div></div>')
        const $videoDOM = $('<video class="player" width="1080" height="1920">');
        const $selectArtistIButtonDOM = $(`<button>${artist.name}</button>`);
        const $logoDOM = $('<div class="logo"><img src="assets/images/logo-a-magia-do-galo@2x.png" alt="Exposição A Magia do Galo" /></div>');

        $videoDOM.append(`<source src="assets/videos/${artist.file}#t=0.5" type="video/mp4" preload="metadata" />`);
        $videoDOM.append('<object data="" width="1080" height="1920" />');
        $videoDOM.append(`<embed width="1080" height="1920" src="assets/videos/${artist.file}#t=0.5" />`);
        $videoDOM.on('ended', () => { onVideoEnded(index) });

        switch (true) {
          case (index === activeArtist):
            $videoItemDOM.addClass('active');
            break;

          case (index < activeArtist):
            $videoItemDOM.addClass('previous');
            break;

          default:
            $videoItemDOM.addClass('upcoming');
            break;
        }

        $videoItemDOM.append($nameDOM, $details, $videoDOM, $logoDOM);
        $videoGallery.append($videoItemDOM);

        if (hasLoading) {
          $videoDOM.on('canplay', () => {
            loaded += 1;

            let current = 100 * (loaded / data.length);
  
            if (hasLoadingValue) {
              $loadingValue.css('width', `${current}%`);
            }

            if (hasLoadingPercentage) {
              $loadingPercentage.text(`${current.toFixed(2)}%`);
            }

            if (current >= 100) {
              $loading.addClass('hide');
            }
          });
        }

        $selectArtistIButtonDOM.click(() => { onClickArtist(index) });
        $selectArtistItemDOM.append($selectArtistIButtonDOM);
        $artistsList.find('ul').append($selectArtistItemDOM);
      }
    });

    if (hasStart) {
      $start.click(onClickStart);
    }

    document.addEventListener('gesturestart', (event) => {
      event.preventDefault();
      document.body.style.zoom = 0.99;
    });
    document.addEventListener('gesturechange', (event) => {
      event.preventDefault();
      document.body.style.zoom = 0.99;
    });
    document.addEventListener('gestureend', (event) => {
      event.preventDefault();
      document.body.style.zoom = 1;
    });
  }
};

$(document).ready(async () => await onDocumentReady());
